import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import {
    Button,
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    Row,
    Col,
} from "reactstrap";
import moment from "moment";
import { Bar } from "react-chartjs-2";

import { startActionWithPromise } from "../../../helpers/saga-promise-helpers";
import { fetchVideoStatsAggAction } from "../../../redux/reducers/videoStatisticsReducer";

const AGG_PERIOD = {
    DAILY: { name: 'Daily', code: 'DAILY' },
    WEEKLY: { name: 'Weekly', code: 'WEEKLY' },
    MONTHLY: { name: 'Monthly', code: 'MONTHLY' },
}

const VideoStatisticsWidget = ({ dateRange, refresh }) => {
    const dispatch = useDispatch();
    const [unit, setUnit] = useState(AGG_PERIOD.DAILY);

    const handleUnitChange = (selectedUnit) => {
        setUnit(selectedUnit);
    }

    useEffect(() => {
        const dates = dateRange.split('-');
        const params = {
            aggType: unit.code,
            startDate: moment(dates[0], 'MM/DD/YYYY'),
            endDate: moment(dates[1], 'MM/DD/YYYY'),
        };

        fetchData(params);

    }, [dateRange, refresh, unit]);

    const fetchData = (params) => {
        (async () => {
            try {
                await startActionWithPromise(
                    fetchVideoStatsAggAction,
                    {
                        params,
                        successCallback: (data) => { transformData(data); },
                        failedCallback: () => { },
                    },
                    dispatch
                );
            } catch { }
        })();
    };


    const log10 = (duration) => {
        if (duration && duration > 0) {
            const result = Math.log10(duration);
            return result < 0 ? 0 : result;
        }
        return 0;
    }

    const transformData = (source) => {
        const newSeries = {
            labels: ['No data'],
            datasets: [
                {
                    label: "F (login)",
                    data: [],
                    backgroundColor: "rgb(237, 14, 12)",
                    maxBarThickness: 15,
                    stack: "F",
                },
                {
                    label: "F (non-login)",
                    data: [],
                    backgroundColor: "rgb(237, 14, 12, 0.4)",
                    maxBarThickness: 15,
                    stack: "F",
                },

                {
                    label: "U (login)",
                    data: [],
                    backgroundColor: "rgb(135, 135, 137)",
                    maxBarThickness: 15,
                    stack: "U",
                },
                {
                    label: "U (non-login)",
                    data: [],
                    backgroundColor: "rgb(135, 135, 137, 0.4)",
                    maxBarThickness: 15,
                    stack: "U",
                },

                {
                    label: "V (login)",
                    data: [],
                    backgroundColor: "rgb(64, 142, 198)",
                    maxBarThickness: 15,
                    stack: "V",
                },
                {
                    label: "V (non-login)",
                    data: [],
                    backgroundColor: "rgb(64, 142, 198, 0.4)",
                    maxBarThickness: 15,
                    stack: "V",
                },

                {
                    label: "I (login)",
                    data: [],
                    backgroundColor: "rgb(220, 99, 42)",
                    maxBarThickness: 15,
                    stack: "I",
                },
                {
                    label: "I (non-login)",
                    data: [],
                    backgroundColor: "rgb(220, 99, 42, 0.4)",
                    maxBarThickness: 15,
                    stack: "I",
                },

                /*
                {
                    label: "Dur. (login)",
                    data: [],
                    backgroundColor: "rgb(75, 162, 235)",
                    maxBarThickness: 15,
                    stack: "DUR",
                },
                {
                    label: "Dur. (non-login)",
                    data: [],
                    backgroundColor: "rgb(75, 162, 235, 0.4)",
                    maxBarThickness: 15,
                    stack: "DUR",
                },
                */
            ]
        };

        if (source.videoStatistics?.length > 0) {
            const videoStatistics = source.videoStatistics[0];
            newSeries.labels = videoStatistics.statistics.map(stats => stats.label);
            newSeries.datasets[0].data = videoStatistics.statistics.map(stats => stats.fbcount);
            newSeries.datasets[1].data = videoStatistics.statistics.map(stats => stats.fbcountVis);
            newSeries.datasets[2].data = videoStatistics.statistics.map(stats => stats.ubcount);
            newSeries.datasets[3].data = videoStatistics.statistics.map(stats => stats.ubcountVis);
            newSeries.datasets[4].data = videoStatistics.statistics.map(stats => stats.vbcount);
            newSeries.datasets[5].data = videoStatistics.statistics.map(stats => stats.vbcountVis);
            newSeries.datasets[6].data = videoStatistics.statistics.map(stats => stats.ibcount);
            newSeries.datasets[7].data = videoStatistics.statistics.map(stats => stats.ibcountVis);
            //newSeries.datasets[8].data = videoStatistics.statistics.map(stats => stats.viewDuration);
            //newSeries.datasets[9].data = videoStatistics.statistics.map(stats => stats.visitorViewDuration);
        }

        setSeries(newSeries);
    }

    const formatDuration = (given_seconds) => {
        const hours = Math.floor(given_seconds / 3600);
        const minutes = Math.floor((given_seconds - (hours * 3600)) / 60);
        const seconds = given_seconds - (hours * 3600) - (minutes * 60);

        const timeString = hours.toString().padStart(2, '0') + ':' +
            minutes.toString().padStart(2, '0') + ':' +
            seconds.toString().padStart(2, '0');
        return timeString;
    }

    const [series, setSeries] = useState({
        labels: ["No data"],
        datasets: [
            {
                label: "F (login)",
                data: [],
                backgroundColor: "rgb(237, 14, 12)",
                maxBarThickness: 15,
                stack: "F",
            },
            {
                label: "F (lon-login)",
                data: [],
                backgroundColor: "rgb(237, 14, 12, 0.4)",
                maxBarThickness: 15,
                stack: "F",
            },

            {
                label: "U (login)",
                data: [],
                backgroundColor: "rgb(135, 135, 137)",
                maxBarThickness: 15,
                stack: "U",
            },
            {
                label: "U (non-login)",
                data: [],
                backgroundColor: "rgb(135, 135, 137, 0.4)",
                maxBarThickness: 15,
                stack: "U",
            },

            {
                label: "V (login)",
                data: [],
                backgroundColor: "rgb(64, 142, 198)",
                maxBarThickness: 15,
                stack: "V",
            },
            {
                label: "V (non-login)",
                data: [],
                backgroundColor: "rgb(64, 142, 198, 0.4)",
                maxBarThickness: 15,
                stack: "V",
            },

            {
                label: "I (login)",
                data: [],
                backgroundColor: "rgb(220, 99, 42)",
                maxBarThickness: 15,
                stack: "I",
            },
            {
                label: "I (non-login)",
                data: [],
                backgroundColor: "rgb(220, 99, 42, 0.4)",
                maxBarThickness: 15,
                stack: "I",
            },

            /*

            {
                label: "Dur. (login)",
                data: [],
                backgroundColor: "rgb(75, 162, 235)",
                maxBarThickness: 15
            },
            {
                label: "Dur. (non-login)",
                data: [],
                backgroundColor: "rgb(75, 162, 235, 0.4)",
                maxBarThickness: 15
            },

            */

        ]
    });

    const options = {
        maintainAspectRatio: false,
        legend: {
            display: false,            
        },
        scales: {
            yAxes: [
                {
                    stacked: true,
                    gridLines: {
                        display: true,
                    },
                    ticks: {
                        beginAtZero: true,
                    },
                },
            ],
            xAxes: [
                {
                    stacked: true,
                    gridLines: {
                        display: false,
                        color: "transparent",
                    },
                },
            ],
        },
    };

    const dayButton = useRef(null);
    const weekButton = useRef(null);
    const monthButton = useRef(null);

    return (
        <Card className="w-100 h-100 mb-0">
            <CardHeader className="">
                <div className="float-right report-date-range">
                    <div className="card-actions d-flex justify-content-end">
                        <Button
                            className="mr-1"
                            color={unit.code === AGG_PERIOD.DAILY.code ? "secondary" : "light"}
                            size="sm"
                            onClick={() => handleUnitChange(AGG_PERIOD.DAILY)}
                            innerRef={dayButton}
                        >
                            {AGG_PERIOD.DAILY.name}
                        </Button>
                        <Button
                            className="mr-1"
                            color={unit.code === AGG_PERIOD.WEEKLY.code ? "secondary" : "light"}
                            size="sm"
                            onClick={() => handleUnitChange(AGG_PERIOD.WEEKLY)}
                            innerRef={weekButton}
                        >
                            {AGG_PERIOD.WEEKLY.name}
                        </Button>
                        <Button
                            color={unit.code === AGG_PERIOD.MONTHLY.code ? "secondary" : "light"}
                            size="sm"
                            onClick={() => handleUnitChange(AGG_PERIOD.MONTHLY)}
                            innerRef={monthButton}
                        >
                            {AGG_PERIOD.MONTHLY.name}
                        </Button>
                    </div>
                </div>
                <CardTitle tag="h5" className="mb-0">
                    <span className="border-bottom">F - U - V - I click counts</span>
                </CardTitle>
            </CardHeader>
            <CardBody className="pt-0 pb-0 h-100 position-relative">
                <Row className='h-100'>
                    <Col lg="12" className='h-100'>
                        <Bar
                            height={300}
                            data={series}
                            options={options} />
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
}
export default VideoStatisticsWidget;