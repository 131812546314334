import React, { useState, useEffect, useRef } from "react";
import { Bar } from "react-chartjs-2";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import PropTypes from "prop-types";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Spinner
} from "reactstrap";

import { startActionWithPromise } from "../../../helpers/saga-promise-helpers";
import { fetchSeedsTeacherAction } from "../../../redux/reducers/reportReducer";

const propTypes = {
  dateRange: PropTypes.string,
  refresh: PropTypes.number
}

const defaultProps = {
  dateRange: '',
  refresh: () => { }
}

const DAILY = "daily";
const WEEKLY = "weekly";
const MONTHLY = "monthly";

const SeedsTeachersWidget = ({ dateRange, refresh }) => {
  const dispatch = useDispatch();

  const dayButton = useRef(null);
  const weekButton = useRef(null);
  const monthButton = useRef(null);

  const seedsTeachers = useSelector(state => state.report.seedsTeachers);

  const [timeline, setTimeline] = useState(DAILY);
  const [waiting, setWaiting] = useState(false);


  const fetchData = (start, end) => {
    (async () => {
      try {
        setWaiting(true);
        await startActionWithPromise(
          fetchSeedsTeacherAction,
          {
            params: {
              startDate: start,
              endDate: end
            },
            successCallback: (response) => {
              console.log(response);
            },
            failedCallback: () => { },
          },
          dispatch
        );
        setWaiting(false);        
      } catch { }
    })();
  }

  useEffect(() => {
    if (refresh) {
      const dates = dateRange.split('-');
      if (dates.length === 2) {
        fetchData(dates[0], dates[1]);
      }
    }
  }, [refresh]);

  useEffect(() => {
    const dates = dateRange.split('-');
    if (dates.length === 2) {
      fetchData(dates[0], dates[1]);
    }

  }, []);



  const getLabels = () => {
    const source = seedsTeachers[timeline] || [];
    return (source && source.map(item => item.date)) || [];
  };

  const getData = () => {
    const source = seedsTeachers[timeline] || [];
    return (source && source.map(item => item.count)) || [];
  };

  const data = {
    labels: getLabels(),
    datasets: [
      {
        label: "Seeds teacher counts",
        data: getData(),
        backgroundColor: "rgb(54, 162, 235)",
        maxBarThickness: 15
      },  
    ],
  };

  const options = {
    maintainAspectRatio: false,
    legend: {
      display: false,
      labels: {
        boxWidth: 13
      }
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            display: true,
          },
          stacked: false,
          ticks: {
            beginAtZero: true,
            // stepSize: 20
          },
        },
      ],
      xAxes: [
        {
          stacked: false,
          gridLines: {
            color: "transparent",
          },
        },
      ],
    },
  };

  return (
    <Card className="flex-fill w-100 border">
      <CardHeader>
        <div className="float-right report-date-range">
          <div className="card-actions d-flex justify-content-end">
            <Button
              className="mr-1"
              color={timeline === DAILY ? "secondary" : "light"}
              size="sm"
              onClick={() => setTimeline(DAILY)}
              innerRef={dayButton}
            >
              Daily
            </Button>
            <Button
              className="mr-1"
              color={timeline === WEEKLY ? "secondary" : "light"}
              size="sm"
              onClick={() => setTimeline(WEEKLY)}
              innerRef={weekButton}
            >
              Weekly
            </Button>
            <Button
              color={timeline === MONTHLY ? "secondary" : "light"}
              size="sm"
              onClick={() => setTimeline(MONTHLY)}
              innerRef={monthButton}
            >
              Monthly
            </Button>
          </div>
        </div>
        <CardTitle tag="h5" className="mb-0">
          <span className="border-bottom">Seeds teacher registration</span>
        </CardTitle>
      </CardHeader>
      <CardBody className="pt-0 pb-0">
        <div className="chart chart-sm">
          {waiting ? (
            <div className="loading text-center p-2"><Spinner size="sm" /></div>
          ) : (
            <Bar data={data} options={options} />
          )}
        </div>
      </CardBody>
    </Card>
  );
};

SeedsTeachersWidget.propTypes = propTypes;
SeedsTeachersWidget.defaultProps = defaultProps;

export default SeedsTeachersWidget;
