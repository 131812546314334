import React, { useState } from "react";
import { Container, Row, Col } from "reactstrap";
import moment from "moment-timezone";

import Header from "./Header";
import UserRegistrationWidget from "./widgets/UserRegistrationWidget";
import RevenueWidget from "./widgets/RevenueWidget";
import Statistics from "./Statistics";
import SessionCountWidget from "./widgets/SessionCountWidget";
import SessionDurationWidget from "./widgets/SessionDurationWidget";
import VideoStatisticsWidget from "./widgets/VideoStatisticsWidget";
import SeedsTeachersWidget from "./widgets/SeedsTeachersWidget";

moment.tz.setDefault('UTC');

const Dashboard = () => {

  const startDate = moment().subtract(30, 'days').format('MM/DD/YYYY');
  const endDate = moment().format('MM/DD/YYYY');

  const [start, setStart] = useState(startDate);
  const [end, setEnd] = useState(endDate);
  const [dateRange, setDateRange] = useState(`${startDate}-${endDate}`);
  const [refresh, setRefresh] = useState(0);

  const onDateChange = (start, end) => {
    setStart(start);
    setEnd(end);

    setDateRange(`${start}-${end}`);
    setRefresh(refresh + 1);
  }

  const onRefresh = () => {
    setRefresh(refresh + 1);
  }

  return (
    <Container fluid className="p-0 h-100">
      <Header
        onDateChange={onDateChange}
        onRefresh={onRefresh}
        initStart={startDate}
        initEnd={endDate}
      />
      <Statistics refresh={refresh} dateRange={dateRange} />
      <Row>
        <Col lg="6" md="6" className="d-flex">
          <UserRegistrationWidget start={start} end={end} refresh={refresh} dateRange={dateRange} />
        </Col>
        <Col lg="6" md="6" xs="6" className="d-flex">
          <SeedsTeachersWidget refresh={refresh} dateRange={dateRange} />
        </Col>
      </Row>
      <Row>
        <Col lg="6" md="6" xs="6" className="d-flex">
          <SessionCountWidget refresh={refresh} />
        </Col>
        <Col lg="6" md="6" className="d-flex">
          <SessionDurationWidget refresh={refresh} />
        </Col>        
      </Row>
      <Row>
        <Col lg="6" md="6" className="d-flex">
          <VideoStatisticsWidget dateRange={dateRange} refresh={refresh} />
        </Col>
        <Col lg="6" md="6" className="d-flex">
          <RevenueWidget start={start} end={end} refresh={refresh} dateRange={dateRange} />
        </Col>
      </Row>
    </Container>
  );
};

export default Dashboard;
