import React, { useState, useEffect, useRef } from "react";
import { Line, Bar } from "react-chartjs-2";
import { startActionWithPromise } from "../../../helpers/saga-promise-helpers";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Spinner
} from "reactstrap";

import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";

import { useDispatch } from "react-redux";
import { getHourlyActiveSessionsAction } from "../../../redux/reducers/reportReducer";

const SessionCountWidget = ({ refresh }) => {
    const dispatch = useDispatch();

    const [asOfDate, setAsOfDate] = useState(new Date());
    const [hourlyActiveSessions, setHourlyActiveSessions] = useState({})

    const getHourlyActiveSessions = async () => {
        try {
            const now = new Date();
            let data = await startActionWithPromise(
                getHourlyActiveSessionsAction,
                {
                    params: {
                        query: 'SESSION',
                        asOfDate: asOfDate,
                        minsTimeOffset: now.getTimezoneOffset(),
                    },
                    successCallback: () => { },
                    failedCallback: () => { },
                },
                dispatch
            );
            setHourlyActiveSessions(data);
        } catch { }
    }

    const fetchData = () => {
        (async () => {
            await getHourlyActiveSessions();
        })();
    }

    useEffect(() => {
        fetchData();
    }, [asOfDate, refresh]);

    useEffect(() => {
        fetchData();
    }, []);

    const countSessions = (visitor) => {
        if (visitor) {
            const len = (hourlyActiveSessions.hourlyCountsVis || []).length;
            if (len == 0)
                return 0;
            return hourlyActiveSessions.hourlyCountsVis.reduce((a, b) => a + b, 0);
        }

        const len = (hourlyActiveSessions.hourlyCounts || []).length;
        if (len == 0)
            return 0;
        return hourlyActiveSessions.hourlyCounts.reduce((a, b) => a + b, 0);
    }

    const chartData = () => {
        const chartData = {
            labels: hourlyActiveSessions.hourlyLabels,
            datasets: [
                {
                    label: `Non-login sessions`,
                    data: hourlyActiveSessions.hourlyCountsVis,
                    fill: false,
                    borderWidth: 2,
                    borderColor: "rgb(75, 162, 235, 0.5)"
                },
                {
                    label: `Login sessions`,
                    data: hourlyActiveSessions.hourlyCounts,
                    fill: false,
                    borderWidth: 3,
                    borderColor: "rgba(75,192,192)"
                },
            ]
        }
        return chartData;
    }

    const chartOptions = () => {
        const options = {
            tooltips: {
                callbacks: {
                    label: (tooltipItem, data) => {
                        if (tooltipItem?.datasetIndex == 0)
                            return 'Non-login sessions: ' + tooltipItem.yLabel;
                        return 'Login sessions: ' + tooltipItem.yLabel;
                    },
                }
            },
            maintainAspectRatio: false,
            legend: {
                display: true,
                labels: {
                    boxWidth: 13
                }
            },
            scales: {
                xAxes: [
                    {
                        stacked: true
                    }
                ],
                yAxes: [
                    {
                        stacked: false
                    }
                ]
            },
            pan: {
                enabled: true,
                mode: "x"
            },
            zoom: {
                enabled: true,
                mode: "x",
                sensitivity: 0.5
            }
        };
        return options;
    }

    return (
        <Card className="flex-fill w-100 border">
            <CardHeader>
                <div className="d-flex w-100">
                    <CardTitle tag="h5" className="mb-0 flex-shrink-0">
                        <span className="border-bottom">Hourly session counts</span>
                    </CardTitle>
                    <div className=" d-flex w-100 justify-content-end">
                        <div className="mr-2 font-weight-bold">
                            <span className="mr-2">{`Login: ${countSessions(false)}, non-login: ${countSessions(true)}`}</span>
                        </div>
                        <DatePicker className="ml-3" selected={asOfDate} onChange={(date) => setAsOfDate(date)} />
                        <div>
                            <Button
                                className="ml-1"
                                size="sm"
                                onClick={() => fetchData()}
                            >
                                Refresh
                            </Button>
                        </div>
                    </div>
                </div>
            </CardHeader>
            <CardBody className="pt-0 pb-0">
                <div className="chart chart-sm">
                    {hourlyActiveSessions?.hourlyCounts && <Line data={chartData()} options={chartOptions()} />}
                </div>
            </CardBody>
        </Card>
    );
}

export default SessionCountWidget;